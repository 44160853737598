<template>
  <v-row class="">
    <v-col class="col-sm-1">{{label}}:</v-col>
    <v-col class="col-sm-2 grey">{{value}}</v-col>
  </v-row>
</template>

<script>
export default {
  name: "field",
  props:['label','value']
}
</script>

<style scoped>

</style>