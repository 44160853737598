<template>
  <v-container>
    <v-card>
      <v-card-title>工单详情</v-card-title>
      <v-card-subtitle>{{order.code}} -  <serviceOrderStatus :status="order.status"></serviceOrderStatus> </v-card-subtitle>
      <!--    <v-card-text>-->
      <!--      <v-row>-->
      <!--        <v-col cols="6" v-for="field in fields" :key="field.key">-->
      <!--          <v-text-field-->
      <!--              :label="field.name"-->
      <!--              :value="order[field.key]"-->
      <!--              outlined-->
      <!--              :readonly="!field.editable"-->
      <!--          ></v-text-field>-->
      <!--        </v-col>-->
      <!--      </v-row>-->
      <!--    </v-card-text>-->

      <v-card-title>问题图片</v-card-title>
      <v-card-text>
        <v-row>
          <v-col
              cols="6"
              sm="4"
          >
            <v-img
                src="https://cdn.vuetifyjs.com/images/parallax/material2.jpg"
                gradient="to top right, rgba(100,115,201,.33), rgba(25,32,72,.7)"
            ></v-img>
          </v-col>

          <v-col
              cols="6"
              sm="4"
          >
            <v-img src="https://cdn.vuetifyjs.com/images/parallax/material2.jpg">
              <div class="fill-height bottom-gradient"></div>
            </v-img>
          </v-col>

          <v-col
              cols="6"
              sm="4"
          >
            <v-img src="https://cdn.vuetifyjs.com/images/parallax/material2.jpg">
              <div class="fill-height repeating-gradient"></div>
            </v-img>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card >
    <v-card class="mt-4">
      <v-card-title>工单详情</v-card-title>
    </v-card>
    <v-card class="mt-4">
      <v-card-title>Ocr 测试</v-card-title>
      <v-card-text>
        <v-text-field
            v-model="url"
            counter="25"
            hint="This field uses counter prop"
            label="Regular"
        ></v-text-field>
        <v-btn :disabled="!url" @click="getText"> 试试看</v-btn>
        <pre>{{text}}</pre>
      </v-card-text>
    </v-card>
  </v-container>

</template>

<script>
import field from "@/views/service/component/field";
import db from "@/plugins/db";
import serviceOrderStatus from "@/views/service/component/serviceOrderStatus.vue";
import cloudbase from "@/plugins/cloudbase";

export default {
  name: "detail",
  components: {field,serviceOrderStatus},
  props:['orderId'],
  data() {
    return {
      url:'',
      text:{},
      fields: [
        {key: 'contractCode', name: '合同号'},
        {key: 'requestDate', name: '申请日期'},
        {key: 'customerName', name: '客户'},
        {key: 'customerPhone', name: '客户电话'},
        {key: 'brand', name: '品牌'},
        {key: 'category', name: '品类'},
        {key: 'lastupdate', name: '修改人'},
        {key: 'lastupdateDate', name: '最后修改时间'},
        {key: 'mainOrder', name: '主商品订单号'},
        {key: 'progressDesc', name: '备注'},
        {key: 'receiveAddress', name: '收货地址'},
        {key: '主商品发票金额', name: '主商品发票金额'},
        {key: '实际补差金额', name: '实际补差金额'},
        {key: '客户补差时间', name: '客户补差时间'},
        {key: '新品价格', name: '新品价格'},
        {key: '新品品牌', name: '新品品牌'},
        {key: '新品品类', name: '新品品类'},
        {key: '新品快递信息', name: '新品快递信息'},
        {key: '新品类型', name: '新品类型'},
        {key: '新品订单号', name: '新品订单号'},
        {key: '新品订单截图', name: '新品订单截图'},
        {key: '新品采购时间', name: '新品采购时间'},
        {key: '新品采购金额', name: '新品采购金额'},
        {key: '旧品核验描述', name: '旧品核验描述'},
        {key: '旧品核验照片', name: '旧品核验照片'},
        {key: '服务商收货时间', name: '服务商收货时间'},
        {key: '服务类型', name: '服务类型'},
        {key: '服务限额', name: '服务限额'},
        {key: '期望更换的新品链接', name: '期望更换的新品链接'},
        {key: '补差提醒', name: '补差提醒'},
        {key: '补差订单号', name: '补差订单号'},
        {key: '赔付比例', name: '赔付比例'},
        {key: '预补差金额', name: '预补差金额'},
      ],
      order: {},
      readonly:true
    }
  },
  created() {
    db.collection('serviceOrders')
        .doc(this.orderId)
        .get()
        .then(v => {
          this.order = v.data[0]
          console.log(this.order)
        })
  },
  methods:{
    getText(){
      cloudbase.callFunction({
        name: 'ocr-invoice',
        data:{
          ImageUrl:this.url,
        }
      }).then(s=>{
        this.text = s.result
      })
    }
  }
}
</script>

<style scoped>

</style>