<template>
  <v-chip
      :color="status.color"
      text-color="white"
      class="ma-2"
      small
  >
    {{ status.name }}
  </v-chip>
</template>

<script>
export default {
  name: "serviceOrderStatus",
  props: {
    code: {
      type: Number,
      default: 0
    },
    project: {
      type: String,
      default: ''
    },
  },
  data() {
    return {}
  },
  computed: {
    status() {
      let status = this.$store.state.projectSettings
          .find(project => {
            return project.name === this.project
          }).status
          .find(s => s.code === this.code)
      if (status) {
        return status
      }
      return {
        name: '',
        code: 1,
        color: ''
      }
    }

  }
}
</script>

<style scoped>

</style>